/**
 * Functions of processing date format
 * Created by Ching-Hsuan Su on 2020/12/09.
 */

import moment from 'moment'
// import tz from 'moment-timezone';

export default class DateUtil {
  /**
   * @desc 將日期格式化 e.g. 2020-02-07
   * @param oriDate {string}
   * @return {string} 格式化後的日期格式
   */
  static formatDate(oriDate) {
    // let timezone = "GMT";
    return moment(oriDate).format('YYYY-MM-DD')
  }

  /**
   * @desc 將日期格式化 e.g. 2020-02-07 21:01:30
   * @param oriDate
   * @return {string} 格式化後的日期格式
   */
  static formatDatetime(oriDate) {
    // let timezone = "GMT";
    return moment(oriDate).format('YYYY-MM-DD HH:mm')
  }

  /**
   * @desc 日期與今天的天數差
   * @param date
   * @return {int} 天數差
   */
  static diff(date) {
    let today = moment();
    let targetDate = moment(date);

    let diffDay = targetDate.diff(today, 'day');
    return diffDay+1;
  }

  /**
   * @desc 將前後兩日期與今天日期轉換成百分制進度值
   * @param sd {string} 起始日期
   * @param ed {string} 結束日期
   * @return {int} 天數差
   */
  static progressValue(sd, ed) {
    let today = moment();
    let startDate = moment(sd);
    let endDate = moment(ed);
    
    let diffStartEndDay = endDate.diff(startDate, 'day') + 1;
    let diffTodayStartDay = today.diff(startDate, 'day') + 1;
    
    return Math.round(diffTodayStartDay/diffStartEndDay*100)
  }

  /**
   * @desc 當前日期
   * @return {str} 日期
   */
  static currentDate() {    
    return moment(new Date()).format("YYYY-MM-DD")
  }

  /**
   * @desc 當前日期時間
   * @return {str} 日期時間
   */
  static currentDatetime() {    
    return moment(new Date()).format("YYYY-MM-DD hh:mm:ss");
  }

  /**
   * @desc 延遲一段時間
   * @param {int} seconds 延遲秒數
   */
  static delay(seconds) {    
    return new Promise(resolve => setTimeout(resolve, seconds*1000));
  }
}